import axios from '@/plugins/axios';
import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import type { Dictionary } from '@kanaboy/utilities/dist/type';
import type { CompensationEmployee, CompensationPosition, SurgeryCompensationPosition } from '@kanaboy/around/dist/type'
import type {
  ImportantUserInfoProperties,
  BioRequest,
  WorkingRequest,
} from '@kanaboy/utilities/dist/user-type';
import { isEmptyDictionary } from '@kanaboy/utilities/dist/utilities';

export const useAccountStore = defineStore('AccountStore', {
  state: () => ({
    id: 0,
    name: '',
    role: '',
    avatar: '',

    // Bio
    prefixName: { id: 0, name: '' },
    firstname: '',
    lastname: '',
    nickname: '',
    cid: '',

    // Working
    employeeType: { id: 0, name: '' },
    position: { id: 0, name: '' },
    officerLevel: { id: 0, name: '' },
    departments: [{ id: 0, name: '' }],

    // Compensation
    compensationEmployee: undefined as CompensationEmployee | undefined,
    compensationPosition: undefined as CompensationPosition | undefined,
    oncallCompensationPosition: undefined as CompensationPosition | undefined,
    surgeryCompensationPosition: undefined as SurgeryCompensationPosition | undefined,
    specialCompensationPosition: undefined as CompensationPosition | undefined,

    googleApiKey: 'AIzaSyD-2BgvMQlsQam8upwnHPDEiN_SX81aE9s',
  }),

  getters: {
    isOfficer(state) {
      return (
        !isEmptyDictionary(state.employeeType) && state.employeeType.id === 97
      );
    },
  },

  actions: {
    // 👉 Get single user
    getUser() {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .get('/api/mobile/profile')
          .then((response) => {
            const { data } = response;

            this.id = data.id;
            this.name = data.name;
            this.avatar = data.avatar;
            this.role = data.role;

            // Bio
            this.prefixName = data.prefixName;
            this.firstname = data.firstname;
            this.lastname = data.lastname;
            this.nickname = data.nickname;
            this.cid = data.cid;

            // Working
            this.employeeType = data.employeeType;
            this.position = data.position;
            this.officerLevel = data.officerLevel;
            this.departments = data.departments;

            // Compensation
            this.compensationEmployee = data.compensationEmployee;
            this.compensationPosition = data.compensationPosition;
            this.oncallCompensationPosition = data.oncallCompensationPosition;
            this.surgeryCompensationPosition = data.surgeryCompensationPosition;
            this.specialCompensationPosition = data.specialCompensationPosition;

            resolve(data);
          })
          .catch((error) => reject(error));
      });
    },

    // 👉 Update Important
    updateImportantUserInfo(properties: ImportantUserInfoProperties) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post('/api/mobile/profile/important', properties)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // 👉 Update Bio
    updateBio(properties: BioRequest) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post('/api/mobile/profile/bio', properties)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // 👉 Update Working
    updateWorking(properties: WorkingRequest) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/mobile/profile/working', properties)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // Selects
    getPrefixNames() {
      return new Promise<Dictionary[]>((resolve, reject) => {
        axios
          .get('/api/base/account/select/prefix-name')
          .then((response) => {
            const { data } = response;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getEmployeeTypes() {
      return new Promise<Dictionary[]>((resolve, reject) => {
        axios
          .get('/api/base/account/select/employee-type')
          .then((response) => {
            const { data } = response;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getPositions() {
      return new Promise<Dictionary[]>((resolve, reject) => {
        axios
          .get('/api/base/account/select/position')
          .then((response) => {
            const { data } = response;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getOfficerLevels(positionId: number) {
      return new Promise<Dictionary[]>((resolve, reject) => {
        axios
          .get(`/api/base/account/select/officer-level/${positionId}`)
          .then((response) => {
            const { data } = response;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getDepartments() {
      return new Promise<Dictionary[]>((resolve, reject) => {
        axios
          .get('/api/base/account/select/department')
          .then((response) => {
            const { data } = response;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    tryGetLocationFromGoogleMaps(lat: number, lng: number) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.googleApiKey}`
          )
          .then((response) => {
            const { data } = response;
            const { results } = data;
            if (results) {
              const result = results[0];
              resolve(result.formatted_address);
            } else {
              resolve('');
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
});
