import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, RouteLocationRaw } from 'vue-router';
import AppsPage from '@/views/AppsPage.vue';
import {
  isAuthenticated,
  getUserData,
} from '@kanaboy/utilities/dist/user';
import { useAccountStore } from '@/stores/useAccountStore';
import {
  isNullOrUndefined,
  isEmpty,
  isEmptyDictionary,
  isEmptyArray,
} from '@kanaboy/utilities/dist/utilities';

export const go2 = (to: RouteLocationRaw) => {
  router.push(to);
};

const verifyUserData = (): boolean => {
  const userData = getUserData();
  const { firstname, position, departments } = userData;

  return (
    !isEmpty(firstname) &&
    !isEmptyDictionary(position) &&
    !isNullOrUndefined(departments) &&
    !isEmptyArray(departments)
  );
};


const routes: Array<RouteRecordRaw> = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '/',
    component: AppsPage,
    children: [
      {
        path: '',
        redirect: { name: 'home' },
      },
      {
        name: 'home',
        path: 'home',
        component: () => import('@/views/HomePage.vue'),
      },
      {
        name: 'calendar',
        path: 'calendar',
        component: () => import('@/views/CalendarPage.vue'),
      },
      {
        name: 'me',
        path: 'me',
        component: () => import('@/views/MePage.vue'),
      },
    ],
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/RegisterPage.vue'),
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('@/views/ProfilePage.vue'),
  },
  {
    name: 'working',
    path: '/working',
    component: () => import('@/views/WorkingPage.vue'),
  },
  {
    name: 'compensation',
    path: '/compensation',
    component: () => import('@/views/CompensationPage.vue'),
  },
  {
    name: 'x',
    path: '/x',
    component: () => import('@/views/XPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, __, next) => {
  if (to.name !== 'login' && !isAuthenticated()) {
    next({ name: 'login' });
  } else if (to.name !== 'register' && isAuthenticated() && !verifyUserData()) {
    next({ name: 'register' });
  } else if (to.name === 'register' && isAuthenticated() && verifyUserData()) {
    next({ name: 'home' });
  } else {
    const userStore = useAccountStore();
    userStore.$patch(getUserData());
    next();
  }
});

export default router;
