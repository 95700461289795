import type {
  Refer,
  RecordReferRequest,
  ReferStat,
  ReferToday,
  ReferDestination,
} from '@kanaboy/around/dist/refer-type';
import axios from '@/plugins/axios';
import { defineStore } from 'pinia';
import _ from 'lodash';
import { getDateNow } from '@kanaboy/utilities/dist/date';
import { formatDate } from '@kanaboy/utilities/dist/formatter';
import { startOfMonth, endOfMonth } from 'date-fns';
import { isEmpty } from '@kanaboy/utilities/dist/utilities';

export const useReferStore = defineStore('ReferStore', {
  state: () => ({
    refers: [] as Refer[],

    // 👉 Stat
    stats: [] as ReferStat[],
    statTodays: [] as ReferStat[],
    statThisMonths: [] as ReferStat[],

    referTodays: [] as ReferToday[],

    loadeds: false,
    loaded: false,
  }),
  getters: {
    spareAndRefers(): Refer[] {
      return _.filter(this.refers, (obj: Refer) => !isEmpty(obj.type));
    },
  },

  actions: {
    getRefers(
      start: Date,
      end: Date = start,
      isStore = true
    ): Promise<Refer[]> {
      const startDate = formatDate(start);
      const endDate = formatDate(end);
      this.loadeds = false;
      return new Promise<Refer[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/refer/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            if (isStore) {
              this.refers = data.data;
            }

            this.loadeds = true;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    record(params: RecordReferRequest): Promise<number> {
      return new Promise<number>((resolve, reject) => {
        axios
          .post('/api/mobile/refer', params)
          .then((response) => resolve(response.data.message))
          .catch((error) => reject(error));
      });
    },

    getStat(
      start: Date,
      end: Date = start,
      isStore = true
    ): Promise<ReferStat[]> {
      const startDate = formatDate(start);
      const endDate = formatDate(end);
      this.loaded = false;
      return new Promise<ReferStat[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/refer/stat/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            if (isStore) {
              this.stats = data.data;
            }
            this.loaded = true;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getStatToday(): Promise<ReferStat[]> {
      const now = getDateNow();
      const startDate = formatDate(now);
      const endDate = startDate;
      this.loaded = false;
      return new Promise<ReferStat[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/refer/stat/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            this.statTodays = data.data;

            this.loaded = true;
            resolve(this.statTodays);
          })
          .catch((error) => reject(error));
      });
    },

    getStatThisMonth(): Promise<ReferStat[]> {
      const startDateOfMonth = startOfMonth(getDateNow());
      const endDateOfMonth = endOfMonth(getDateNow());
      const startDate = formatDate(startDateOfMonth);
      const endDate = formatDate(endDateOfMonth);
      this.loaded = false;
      return new Promise<ReferStat[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/refer/stat/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            this.statThisMonths = data.data;

            this.loaded = true;
            resolve(this.statThisMonths);
          })
          .catch((error) => reject(error));
      });
    },

    // Selects
    getReferDestinations(): Promise<ReferDestination[]> {
      return new Promise<ReferDestination[]>((resolve, reject) => {
        axios
          .get('/api/mobile/refer/select/destination')
          .then((response) => {
            const { data } = response;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

     getReferToday(isStore = true): Promise<ReferToday[]> {
      return new Promise<ReferToday[]>((resolve, reject) => {
        axios
          .get('/api/mobile/refer/today')
          .then((response) => {
            const { data } = response;
            if(isStore) {
              this.referTodays = data.data
            }
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
});
