import { defineStore } from 'pinia';
import { getDateNow } from '@kanaboy/utilities/dist/date';
import { formatDate } from '@kanaboy/utilities/dist/formatter';
import axios from '@/plugins/axios';
import type { CalendarGroup, CalendarDayEvent } from '@kanaboy/utilities/dist/date-type'
import { initCalendars } from '@kanaboy/utilities/dist/calendar';
import { startOfMonth, addDays } from 'date-fns';

const dateNow = getDateNow()
const startDateLocal = localStorage.getItem('startDate')
const startDate = startDateLocal ? new Date(startDateLocal) : startOfMonth(dateNow)

const endDateNow  = addDays(dateNow, 1)

const endDate = endDateNow

export const useCalendarStore = defineStore('CalendarStore', {
  state: () => ({
    startDate,
    endDate,

    calendarGroups: [] as CalendarGroup[],
    events: [] as CalendarDayEvent<unknown>[],

    listLoaded: false,
  }),

  getters: {
    now(): Date {
      return getDateNow();
    },
    nowFormatted(): string {
      return formatDate(getDateNow());
    },
  },

  actions: {
    init() {
      this.calendarGroups = initCalendars(this.startDate, this.endDate)
    },

    getEvents(): Promise<CalendarDayEvent<unknown>[]> {
      const startDate = formatDate(this.startDate)
      const endDate = formatDate(this.endDate)
      this.listLoaded = false;
      return new Promise<CalendarDayEvent<unknown>[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/calendar/events/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            this.events = data.data;
            this.listLoaded = true;
            resolve(this.events);
          })
          .catch((error) => reject(error));
      });
    },
  },
});
