import type {
  Shift,
  RecordShiftRequest,
  ShiftStat,
  ShiftTodayByDepartment,
} from '@kanaboy/around/dist/shift-type';
import axios from '@/plugins/axios';
import { defineStore } from 'pinia';
import _ from 'lodash';
import { getDateNow } from '@kanaboy/utilities/dist/date';
import { formatDate } from '@kanaboy/utilities/dist/formatter';
import { startOfMonth, endOfMonth } from 'date-fns';

export const useShiftStore = defineStore('ShiftStore', {
  state: () => ({
    shifts: [] as Shift[],

    shiftTodayByDepartments: [] as ShiftTodayByDepartment[],

    // 👉 Stat
    stats: [] as ShiftStat[],
    statTodays: [] as ShiftStat[],
    statThisMonths: [] as ShiftStat[],

    loadeds: false,
    loaded: false,
  }),
  getters: {
    shiftCheckeds(): Shift[] {
      return _.filter(this.shifts, (obj: Shift) => obj.checked);
    },
    reds(): Shift[] {
      return _.filter(this.shifts, (obj: Shift) => obj.type === 'red');
    },
    blacks(): Shift[] {
      return _.filter(this.shifts, (obj: Shift) => obj.type === 'black');
    },
    // Stat
    statReds(): ShiftStat[] {
      return _.filter(this.stats, (obj: ShiftStat) => obj.type === 'red');
    },
    statBlacks(): ShiftStat[] {
      return _.filter(
        this.stats,
        (obj: ShiftStat) => obj.type === 'black'
      );
    },
    totalRed(): number {
      const filters = _.filter(
        this.stats,
        (obj: ShiftStat) => obj.type === 'red'
      );
      return _.sumBy(filters, (obj: ShiftStat) => {
        return obj.total;
      });
    },
    totalBlack(): number {
      const filters = _.filter(
        this.stats,
        (obj: ShiftStat) => obj.type === 'black'
      );
      return _.sumBy(filters, (obj: ShiftStat) => {
        return obj.total;
      });
    },

    // Today
    statNotZeroTodays(): ShiftStat[] {
      return _.filter(this.statTodays, (obj: ShiftStat) => obj.total > 0);
    },
    totalRedToday(): number {
      const filters = _.filter(
        this.statTodays,
        (obj: ShiftStat) => obj.type === 'red'
      );
      return _.sumBy(filters, (obj: ShiftStat) => {
        return obj.total;
      });
    },
    totalBlackToday(): number {
      const filters = _.filter(
        this.statTodays,
        (obj: ShiftStat) => obj.type === 'black'
      );
      return _.sumBy(filters, (obj: ShiftStat) => {
        return obj.total;
      });
    },

    // This Month
    statRedThisMonths(): ShiftStat[] {
      return _.filter(
        this.statThisMonths,
        (obj: ShiftStat) => obj.type === 'red'
      );
    },
    statBlackThisMonths(): ShiftStat[] {
      return _.filter(
        this.statThisMonths,
        (obj: ShiftStat) => obj.type === 'black'
      );
    },
    totalRedStatThisMonth(): number {
      const filters = _.filter(
        this.statThisMonths,
        (obj: ShiftStat) => obj.type === 'red'
      );
      return _.sumBy(filters, (obj: ShiftStat) => {
        return obj.total;
      });
    },
    totalBlackStatThisMonth(): number {
      const filters = _.filter(
        this.statThisMonths,
        (obj: ShiftStat) => obj.type === 'black'
      );
      return _.sumBy(filters, (obj: ShiftStat) => {
        return obj.total;
      });
    },
  },

  actions: {
    getShiftTodayByDepartments(
      departmentId: number,
      isStore = true
    ): Promise<ShiftTodayByDepartment[]> {
      this.loadeds = false;
      return new Promise<ShiftTodayByDepartment[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/shift/today/${departmentId}`)
          .then((response) => {
            const { data } = response;
            if (isStore) {
              this.shiftTodayByDepartments = data.data;
            }

            this.loadeds = true;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },
    getShifts(
      start: Date,
      end: Date = start,
      isStore = true
    ): Promise<Shift[]> {
      const startDate = formatDate(start);
      const endDate = formatDate(end);
      this.loadeds = false;
      return new Promise<Shift[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/shift/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            if (isStore) {
              this.shifts = data.data;
            }

            this.loadeds = true;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    record(params: RecordShiftRequest): Promise<number> {
      return new Promise<number>((resolve, reject) => {
        axios
          .post('/api/mobile/shift', params)
          .then((response) => resolve(response.data.message))
          .catch((error) => reject(error));
      });
    },

    getStat(
      start: Date,
      end: Date = start,
      isStore = true
    ): Promise<ShiftStat[]> {
      const startDate = formatDate(start);
      const endDate = formatDate(end);
      this.loaded = false;
      return new Promise<ShiftStat[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/shift/stat/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            if (isStore) {
              this.stats = data.data;
            }

            this.loaded = true;
            resolve(data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getStatToday(): Promise<ShiftStat[]> {
      const now = getDateNow();
      const startDate = formatDate(now);
      const endDate = startDate;
      this.loaded = false;
      return new Promise<ShiftStat[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/shift/stat/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            this.statTodays = data.data;

            this.loaded = true;
            resolve(this.statTodays);
          })
          .catch((error) => reject(error));
      });
    },

    getStatThisMonth(): Promise<ShiftStat[]> {
      const startDateOfMonth = startOfMonth(getDateNow());
      const endDateOfMonth = endOfMonth(getDateNow());
      const startDate = formatDate(startDateOfMonth);
      const endDate = formatDate(endDateOfMonth);
      this.loaded = false;
      return new Promise<ShiftStat[]>((resolve, reject) => {
        axios
          .get(`/api/mobile/shift/stat/${startDate}/${endDate}`)
          .then((response) => {
            const { data } = response;
            this.statThisMonths = data.data;

            this.loaded = true;
            resolve(this.statThisMonths);
          })
          .catch((error) => reject(error));
      });
    },
  },
});
