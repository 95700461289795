import axios from '@/plugins/axios';
import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import liff from '@line/liff';

export const useAuthenticationStore = defineStore('AuthenticationStore', {
  actions: {
    // 👉 line login
    login(
      idToken: string | null,
    ) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post('/api/base/authentication/line/login', {
            idToken,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // 👉 line loginDev
    loginDev(
      userId: string,
      displayName: string,
      pictureUrl: string | undefined
    ) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post('/api/base/authentication/line/login/dev', {
            userId,
            displayName,
            pictureUrl,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    logout() {
      localStorage.removeItem('userData');
      localStorage.removeItem('accessToken');
      liff.logout();
      if (liff.isInClient()) {
        liff.closeWindow();
      }
    },
  },
});
