import axios from 'axios'
import type { AxiosRequestHeaders } from 'axios'
import { useAuthenticationStore } from '@/stores/useAuthenticationStore'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
      config.headers = {
        'Authorization': `Bearer ${accessToken}`, 
      } as AxiosRequestHeaders
    }

    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axiosIns.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const authenticationStore = useAuthenticationStore();
  authenticationStore.logout();
  return Promise.reject(error);
});

export default axiosIns
